<template>
    <b-row>
        <b-col sm="12">
            <b-overlay :show="loading">
                <template >
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(generalSubmit)" @reset.prevent="reset" >
                            <iq-card>
                                <template v-slot:headerTitle>
                                    <h5 class="card-title"><!--<i class="ri-edit-line"></i> -->{{ $t('certify_form.requesting_for_seed_testing_without_field_certification') }}</h5>
                                </template>
                                <template v-slot:body>
                                    <b-row>
                                        <b-col sm="12">
                                            <iq-card class="p-3">
                                                <b-row>
                                                    <b-col sm="12">
                                                        <h5 class="ex_form_title">{{$t('globalTrans.organization')}}</h5>
                                                    </b-col>
                                                    <b-col sm="2">
                                                        <b-form-group class="row" label-cols-sm="12" label-for="registered" >
                                                            <template v-slot:label>
                                                                {{$t('request_testing_tag_without_field_certification_application.org_type')}}
                                                            </template>
                                                            <b-form-radio  v-model="formData.org_type_id"  value="1">{{$t('request_testing_tag_without_field_certification_application.gov')}}</b-form-radio>
                                                            <b-form-radio  v-model="formData.org_type_id"  value="2">{{$t('request_testing_tag_without_field_certification_application.non_gov')}}</b-form-radio>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <b-form-group class="row" label-cols-sm="12" label-for="applicant_org_name">
                                                            <template v-slot:label>
                                                                {{$t('certify_form.applicants_org_name')}}
                                                            </template>
                                                            <b-form-input
                                                                plain
                                                                id="applicant_org_name"
                                                                v-model="formData.applicant_org_name"
                                                            >
                                                            </b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <b-form-group class="row" label-cols-sm="12" label-for="applicant_org_name_bn" >
                                                            <template v-slot:label>
                                                                {{$t('certify_form.applicants_org_name_bn')}}
                                                            </template>
                                                            <b-form-input
                                                                plain
                                                                v-model="formData.applicant_org_name_bn"
                                                                id="applicant_org_name_bn"
                                                            >
                                                            </b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                            </iq-card>
                                        </b-col>
                                        <b-col sm="12">
                                            <iq-card class="p-3">
                                                <b-row>
                                                    <b-col sm="12" class="pb-3">
                                                        <h5 class="ex_form_title">{{$t('globalTrans.dealers_information')}}</h5>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Name of Dealer (En)" vid="dealer_name_en" rules="max:50">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="dealer_name_en" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.dealer_name_en')}}
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    id="dealer_name_en"
                                                                    v-model="formData.dealer_name_en"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Name of Dealer (Bn)" vid="dealer_name_bn" rules="required|max:50">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="dealer_name_bn" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.dealer_name_bn')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    id="dealer_name_bn"
                                                                    v-model="formData.dealer_name_bn"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                <!--  start-->
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Area Type Id" vid="a_area_type_id" rules="required|min_value:1">
                                                            <b-form-group
                                                                class="row"
                                                                label-cols-sm="12"
                                                                label-for="a_area_type_id "
                                                                slot-scope="{ valid, errors }"
                                                            >
                                                                <template v-slot:label>
                                                                    {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.a_area_type_id"
                                                                    id="a_area_type_id"
                                                                    :options="getAreaTypeList"
                                                                    @change="getAreaTypeData(formData.a_area_type_id)"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Division" vid="a_division_id" rules="required">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="a_division_id" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('globalTrans.division')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.a_division_id"
                                                                    :options="divisionList"
                                                                    id="a_division_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="District" vid="a_district_id" rules="required">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="a_district_id" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('globalTrans.district')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.a_district_id"
                                                                    :options="districtList"
                                                                    id="a_district_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4" v-if="formData.a_area_type_id === 1">
                                                        <ValidationProvider name="City Corporation" vid="a_city_corporation_id" rules="required">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="a_city_corporation_id" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('globalTrans.city_corporation')}}<span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.a_city_corporation_id"
                                                                    :options="cityCorporationList"
                                                                    id="a_city_corporation_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4" v-if="formData.a_area_type_id > 1">
                                                        <ValidationProvider name="Upazilla" vid="a_upazilla_id" rules="required">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="a_upazilla_id" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('globalTrans.upazila')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.a_upazilla_id"
                                                                    :options="upazilaList"
                                                                    id="a_upazilla_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4" v-if="formData.a_area_type_id === 2">
                                                        <ValidationProvider name="Municipality" vid="a_pouroshova_id" rules="required">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="a_pouroshova_id" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('globalTrans.pouroshova')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.a_pouroshova_id"
                                                                    :options="pauroshobaList"
                                                                    id="a_upazilla_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4" v-if="formData.a_area_type_id === 3">
                                                        <ValidationProvider name="Union" vid="a_union_id" rules="required">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="a_union_id" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('globalTrans.union')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.a_union_id"
                                                                    :options="unionList"
                                                                    id="a_union_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                     <b-col sm="4" v-if="formData.a_area_type_id > 0">
                                                        <ValidationProvider name="Ward" vid="a_ward_id" rules="">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="a_ward_id" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('globalTrans.ward')}}
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.a_ward_id"
                                                                    :options="wardList"
                                                                    id="a_city_corporation_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                <!--  end-->
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Post Office Name" vid="a_post_office_name">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="a_post_office_name" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('certify_form.post_office_name')}}
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    v-model="formData.a_post_office_name"
                                                                    id="a_post_office_name"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Village Name" vid="a_village_name">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="a_village_name" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('certify_form.village_name')}}
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    v-model="formData.a_village_name"
                                                                    id="a_village_name"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Holding Number" vid="a_holding_number">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="a_holding_number" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('certify_form.holding_number_en')}}
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    v-model="formData.a_holding_number"
                                                                    id="a_holding_number"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Holding Number(Bn)" vid="a_holding_number_bn">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="a_holding_number_bn" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('certify_form.holding_number_bn')}}
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    v-model="formData.a_holding_number_bn"
                                                                    id="a_holding_number_bn"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Phone No" vid="a_mobile_phone_no" rules="required|numeric|max:11|min:11">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="a_mobile_phone_no" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('certify_form.mobile_phone_no')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    type="number"
                                                                    v-model="formData.a_mobile_phone_no"
                                                                    id="a_mobile_phone_no"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Email Address" vid="a_email_address" rules="required">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="a_email_address" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('certify_form.email_address')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    type="email"
                                                                    v-model="formData.a_email_address"
                                                                    id="a_email_address"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider :name="$t('request_testing_tag_without_field_certification_application.registered')" vid="registered" rules="required">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="registered" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.registered')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-radio class="d-inline-block mr-4" :state="errors[0] ? false : (valid ? true : null)" v-model="formData.registered"  @change="isDisabledCheck"  value="1">{{$t('request_testing_tag_without_field_certification_application.registered_status')}}</b-form-radio>
                                                                <b-form-radio class="d-inline-block" :state="errors[0] ? false : (valid ? true : null)" v-model="formData.registered"   @change="isDisabledCheck" value="2">{{$t('request_testing_tag_without_field_certification_application.un-registered_status')}}</b-form-radio>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4" v-if="!isDisabled">
                                                        <ValidationProvider :name="$t('request_testing_tag_without_field_certification_application.registration_number')" vid="registration_number" rules="required|max:50">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="registration_number" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.registration_number')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    type="number"
                                                                    id="registration_number"
                                                                    v-model="formData.registration_number"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4" v-else></b-col>
                                                </b-row>
                                            </iq-card>
                                        </b-col>
                                        <b-col md="12">
                                            <iq-card class="p-3">
                                                <b-row>
                                                    <b-col md="12" class="pb-3">
                                                        <h5 class="ex_form_title">{{ $t('external_research.office_information') }}</h5>
                                                    </b-col>
                                                </b-row>
                                                <b-row >
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Office Type" vid="office_type_id" rules="required">
                                                        <b-form-group class="row" label-cols-sm="12" label-for="office_type_id" slot-scope="{ valid, errors }">
                                                            <template v-slot:label>
                                                            {{$t('external_research.office_type')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                                plain
                                                                v-model="formData.office_type_id"
                                                                :options="officeTypeList"
                                                                id="office_type_id"
                                                                disabled
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Office" vid="office_id" rules="required">
                                                        <b-form-group class="row" label-cols-sm="12" label-for="office_id" slot-scope="{ valid, errors }">
                                                            <template v-slot:label>
                                                            {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                                plain
                                                                v-model="formData.office_id"
                                                                :options="officeList"
                                                                id="office_id"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </b-row>
                                            </iq-card>
                                        </b-col>
                                        <b-col md="12">
                                        <!-- {{ formData.seed_infos }} -->
                                            <iq-card class="p-3">
                                                <b-row v-for="(item, index) in formData.seeds_infos" :key="index">
                                                    <b-col md="12" class="pb-3">
                                                        <h5 class="ex_form_title">{{$t('globalTrans.seeds_information')}}</h5>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Seed Class" :vid="`seed_class_id${index}`" rules="required">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_class_id" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('external_research.seed_class')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="item.seed_class_id"
                                                                    :options="seedClassList"
                                                                    :id="`seed_class_id${index}`"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Seed Name" :vid="`seed_name_id${index}`" rules="required">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_name_id" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.seed_name')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="item.seed_name_id"
                                                                    :options="seedList"
                                                                    :id="`seed_name_id${index}`"
                                                                    :v-on="getVariety(item.seed_name_id, index)"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option value="" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4" v-if="item.seed_name_id === 6">
                                                        <ValidationProvider name="Seed Name (Others)" :vid="`seed_name_others${index}`" :rules="item.seed_name_id === 6 ? 'required' : ''">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_name_others" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('testing_and_tag_issue.seed_name_others')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    :id="`seed_name_others${index}`"
                                                                    v-model="item.seed_name_others"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4" v-if="item.seed_name_id !== 6">
                                                        <ValidationProvider name="Variety Name" :vid="`variety_name${index}`" :rules="item.seed_name_id === 6 ? '' : 'required'">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="dealer_name_en" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('testing_and_tag_issue.seed_variety_name')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    :options="item.seedVarietyList"
                                                                    :id="`variety_name${index}`"
                                                                    v-model="item.seed_variety_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option value="" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4" v-if="item.seed_name_id === 6">
                                                        <ValidationProvider name="Variety Name (Others)" :vid="`variety_name_others${index}`" :rules="item.seed_name_id === 6 ? 'required' : ''">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="variety_name_others" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('testing_and_tag_issue.seed_variety_name')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    :id="`variety_name_others${index}`"
                                                                    v-model="item.seed_variety_others_name"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Lot Number" :vid="`lot_number${index}`" rules="required|max:25">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="lot_number" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.lot_number')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    :id="`lot_number${index}`"
                                                                    v-model="item.lot_number"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Amount of Seed in Lot" :vid="`amount_of_seed_in_lot${index}`" rules="max:50">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="dealer_name_en" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.amount_of_seed_in_lot')}}
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    type="number"
                                                                    :id="`amount_of_seed_in_lot${index}`"
                                                                    v-model="item.seed_amount"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Unit Measurement" :vid="`lot_unit_measurement${index}`" rules="">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="lot_unit_measurement" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.unit_measurement')}}
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    :options="seedUnit"
                                                                    :id="`lot_unit_measurement${index}`"
                                                                    v-model="item.unit_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option value="" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Seed Source Name (En)" :vid="`seed_source_name${index}`" rules="">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_source_name" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('certify_form.seed_source_name_en')}}
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    :id="`seed_source_name${index}`"
                                                                    v-model="item.seed_source_name"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Seed Source Name (Bn)" :vid="`seed_source_name_bn${index}`" rules="">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="seed_source_name_bn" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('certify_form.seed_source_name_bn')}}
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    :id="`seed_source_name_bn${index}`"
                                                                    v-model="item.seed_source_name_bn"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <br>
                                                    <b-col sm="12" class="pb-3">
                                                        <h5 class="ex_form_title">{{$t('globalTrans.production_information')}}</h5>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider :name="$t('request_testing_tag_without_field_certification_application.producer_name_en')" :vid="`producer_name_en${index}`">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="producer_name_en" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.producer_name_en')}}
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    :id="`producer_name_en${index}`"
                                                                    v-model="item.producer_name_en"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider :name="$t('request_testing_tag_without_field_certification_application.producer_name_bn')" :vid="`producer_name_bn${index}`">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="producer_name_bn" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.producer_name_bn')}}
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    :id="`producer_name_en${index}`"
                                                                    v-model="item.producer_name_bn"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Production Year" :vid="`production_year${index}`" rules="required">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="production_year" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.production_year')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="item.production_year"
                                                                    :options="YearList"
                                                                    :id="`production_year${index}`"
                                                                    placeholder="Select Production Date"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="date of packing" :vid="`date_of_packing${index}`">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="date_of_packing" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.date_of_packing')}}
                                                                </template>
                                                                <b-form-input class="form-control datepicker"
                                                                    v-model="item.date_of_packing"
                                                                    placeholder="Select packing Date"
                                                                    :id="`date_of_packing${index}`"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider :name="$t('request_testing_tag_without_field_certification_application.date_of_expiry')" :vid="`date_of_expiry${index}`">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="date_of_expiry" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.date_of_expiry')}}
                                                                </template>
                                                                <b-form-input class="form-control datepicker"
                                                                    v-model="item.date_of_expiry"
                                                                    placeholder="Select Expire Date"
                                                                    :id="`date_of_expiry${index}`"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <br>
                                                    <b-col sm="12" class="pb-3">
                                                        <h5 class="ex_form_title"> {{$t('globalTrans.others_information')}}</h5>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Nature of Container (En)" :vid="`nature_of_container_en${index}`" rules="max:50">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="nature_of_container_en" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.nature_of_container_en')}}
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    v-model="item.nature_of_container_en"
                                                                    :id="`nature_of_container_en${index}`"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Nature of Container (bn)" :vid="`nature_of_container_bn${index}`" rules="required|max:50">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="nature_of_container_bn" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.nature_of_container_bn')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-input
                                                                    plain
                                                                    v-model="item.nature_of_container_bn"
                                                                    :id="`nature_of_container_bn${index}`"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Date of Sampling" :vid="`date_of_sampling${index}`" rules="">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="date_of_sampling" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.date_of_sampling')}}
                                                                </template>
                                                                <b-form-input class="form-control datepicker"
                                                                    v-model="item.date_of_sampling"
                                                                    placeholder="Select Sampling Date"
                                                                    :id="`date_of_sampling${index}`"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Sample No" :vid="`sample_no${index}`" rules="required|max:20">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="sample_no" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.sample_no')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-input class="form-control"
                                                                    v-model="item.sample_no"
                                                                    :id="`sample_no${index}`"
                                                                    type="number"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Sample Quantity" :vid="`sample_quantity${index}`" rules="required|max:20">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="sample_quantity" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.sample_quantity')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-input class="form-control"
                                                                    v-model="item.sample_quantity"
                                                                    :vid="`sample_quantity${index}`"
                                                                    type="number"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <ValidationProvider name="Unit Measurement" :vid="`sample_unit_id${index}`" rules="">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="sample_unit_id" slot-scope="{ valid, errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.unit_measurement')}}
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    :options="seedUnit"
                                                                    :id="`sample_unit_id${index}`"
                                                                    v-model="item.sample_unit_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option value="" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="8">
                                                        <ValidationProvider :name="$t('request_testing_tag_without_field_certification_application.locally_produced_imported')" :vid="`locally_produced_imported${index}`" rules="">
                                                            <b-form-group class="row" label-cols-sm="12" label-for="locally_produced_imported" slot-scope="{ errors }">
                                                                <template v-slot:label>
                                                                    {{$t('request_testing_tag_without_field_certification_application.locally_produced_imported')}}
                                                                </template>
                                                                <b-form-radio
                                                                v-model="item.locally_produced_imported"  name="some-radios" value="1">
                                                                {{$t('request_testing_tag_without_field_certification_application.locally_produced')}}
                                                                </b-form-radio>
                                                                <b-form-radio  v-model="item.locally_produced_imported"  name="some-radios" value="2">{{$t('request_testing_tag_without_field_certification_application.imported')}}</b-form-radio>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <div class="text-right mt-4">
                                                            <button type="button" class="btn btn-sm btn-success mr-1" @click="addMore(item)">
                                                                <i class="fas fa-plus mr-0"></i>
                                                            </button>
                                                            <button v-if="formData.seeds_infos.length > 1" class="btn btn-sm btn-danger" @click="remove(index)" type="button"><i class="fas fa-window-close mr-0"></i></button>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </iq-card>
                                        </b-col>
                                    </b-row>
                                    <div class="text-right">
                                        <b-button type="submit" :disabled="isLoading" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                        <b-link class="btn btn-danger" to="/research-farmer/request-testing-tag/request-testing-tag-without-field-certification" variant="primary">
                                            {{ $t('globalTrans.cancel') }}
                                        </b-link>
                                    </div>
                                </template>
                            </iq-card>
                            <!-- <pre>{{formData}}</pre> -->
                        </b-form>
                    </ValidationObserver>
                </template>
            </b-overlay>
        </b-col>
    </b-row>
</template>

<script>
    import { core } from '@/config/pluginInit'
    import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
    import { testingTagRequestWithoutCetificationShow, testingTagRequestWithoutCetificationStore, testingTagRequestWithoutCetificationUpdate } from '../../api/routes'
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import flatpickr from 'flatpickr'
    export default {
        components: {
            ValidationObserver,
            ValidationProvider
        },
        props: ['info'],
        created () {
            this.loading = true
        },
        data () {
            return {
                saveBtnName: this.$t('globalTrans.save'),
                loading: false,
                isLoading: false,
                isDisabled: true,
                isRequired: 'required',
                errors: [],
                cityShow: false,
                pouroShow: false,
                unionShow: false,
                a_divisionList: [],
                a_districtList: [],
                a_upazilaList: [],
                a_unionList: [],
                a_pouroshovaList: [],
                a_city_corporationList: [],
                seedVarietyList: [],
                formData: {
                    id: null,
                    general_info_id: null,
                    dealer_name_en: '',
                    dealer_name_bn: '',
                    registered: '',
                    registration_number: null,
                    dealer_address_bn: '',
                    dealer_address_en: '',
                    seeds_infos: [
                       {
                            seed_class_id: '',
                            seed_name_id: '',
                            seed_variety_id: '',
                            seed_name_others: '',
                            seed_variety_others_name: '',
                            seed_amount: '',
                            unit_id: '',
                            lot_number: '',
                            seed_source_name: '',
                            seed_source_name_bn: '',
                            producer_name_en: '',
                            producer_name_bn: '',
                            production_year: '',
                            date_of_packing: '',
                            date_of_expiry: '',
                            nature_of_container_en: '',
                            nature_of_container_bn: '',
                            date_of_sampling: '',
                            sample_unit_id: '',
                            sample_quantity: '',
                            sample_no: '',
                            locally_produced_imported: 1,
                            seedVarietyList: []
                        }
                    ],
                    office_id: '',
                    office_type_id: 113,
                    a_division_id: '',
                    a_district_id: '',
                    a_upazilla_id: '',
                    a_city_corporation_id: '',
                    a_union_id: '',
                    a_pouroshova_id: '',
                    a_ward_id: '',
                    seed_class_id: '',
                    org_type_id: 2,
                    applicant_org_name: '',
                    applicant_org_name_bn: '',
                    a_area_type_id: 0
                }
            }
        },
        watch: {
            'formData.a_area_type_id': function (newVal, oldVal) {
                this.wardList = this.getWard(newVal)
            },
            'formData.a_city_corporation_id': function (newVal, oldVal) {
                this.wardList = this.getWard(newVal)
            },
            'formData.a_upazilla_id': function (newVal, oldVal) {
                this.wardList = this.getWard(newVal)
            },
            'formData.a_pouroshova_id': function (newVal, oldVal) {
                this.wardList = this.getWard(newVal)
            },
            'formData.a_district_id': function (newVal, oldVal) {
                this.getOfficeName(newVal)
            },
            currentLocale: function () {
                const newVal = 0
                this.wardList = this.getWard(newVal)
            }
        },
        mounted () {
            core.index()
            this.loading = false
            flatpickr('.datepicker', {})
            this.getData()
        },

        computed: {
            currentLocale () {
                return this.$i18n.locale
            },
            seedClassList: function () {
                const classes = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedClassNameList.filter(item => item.status === 0)
                return classes.map(item => {
                    return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
            },
            seedList: function () {
                return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedNameList.filter(item => item.status === 0)
            },
            seedUnit: function () {
                const seedUnit = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList.filter(item => item.status === 0)
                return seedUnit.map(item => {
                    return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
            },
            YearList: function () {
                return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList
            },
            getAreaTypeList: function () {
                const objectData = this.$store.state.commonObj.wardTypeList
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.id, text: obj.name_bn }
                    } else {
                        return { value: obj.id, text: obj.name }
                    }
                })
            },
            divisionList: function () {
                const objectData = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            },
            districtList: function () {
                const objectData = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0 && item.division_id === this.formData.a_division_id)
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            },
            cityCorporationList: function () {
                const objectData = this.$store.state.ExternalUserIrrigation.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === this.formData.a_district_id)
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            },
            upazilaList: function () {
                const objectData = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.status === 0 && item.district_id === this.formData.a_district_id)
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            },
            pauroshobaList: function () {
                const objectData = this.$store.state.ExternalUserIrrigation.commonObj.pauroshobaList.filter(item => item.status === 0 && item.upazilla_id === this.formData.a_upazilla_id)
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            },
            unionList: function () {
                const objectData = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.status === 0 && item.upazilla_id === this.formData.a_upazilla_id)
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            },
            officeTypeList: function () {
            const objectData = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.filter(item => item.org_id === 14)
            return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            }
        },
        methods: {
            getVariety (id, index) {
                this.formData.seeds_infos[index].seedVarietyList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.seedVarietyList.filter(item => item.seed_name_id === parseInt(id)).map(item => {
                    return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
            },
            addMore () {
                this.formData.seeds_infos.push({
                    seed_class_id: '',
                    seed_name_id: '',
                    seed_name_others: '',
                    seed_variety_id: '',
                    seed_variety_others_name: '',
                    seed_amount: '',
                    unit_id: '',
                    lot_number: '',
                    seed_source_name: '',
                    seed_source_name_bn: '',
                    producer_name_en: '',
                    producer_name_bn: '',
                    production_year: '',
                    date_of_packing: '',
                    date_of_expiry: '',
                    nature_of_container_en: '',
                    nature_of_container_bn: '',
                    date_of_sampling: '',
                    sample_unit_id: '',
                    sample_quantity: '',
                    sample_no: '',
                    locally_produced_imported: ''
                })
                this.$nextTick(() => {
                flatpickr('.datepicker', {})
            })
            },
            remove (index) {
                this.formData.seeds_infos.splice(index, 1)
            },
            getAreaTypeData (typeId) {
                this.cityShow = false
                this.pouroShow = false
                this.unionShow = false
                    this.a_districtList = []
                    this.a_upazilaList = []
                    this.a_pouroshovaList = []
                    this.a_city_corporationList = []
                if (typeId === 1) {
                    this.cityShow = true
                } else if (typeId === 2) {
                    this.pouroShow = true
                } else if (typeId === 3) {
                    this.unionShow = true
                }
            },
            isDisabledCheck () {
                if (parseInt(this.formData.registered) === 1) {
                    this.isDisabled = false
                    this.isRequired = ''
                } else {
                    this.isDisabled = true
                    this.isRequired = 'required'
                }
            },
            async  getData () {
                if (this.$route.query.id) {
                    this.loading = true
                    this.saveBtnName = this.$t('globalTrans.update')
                    this.$store.dispatch('mutateCommonProperties', { loading: true })
                    const result = await RestApi.getData(agriResearchServiceBaseUrl, testingTagRequestWithoutCetificationShow + '/' + this.$route.query.id)
                    if (result.success) {
                        if (result.data.is_submit) {
                            this.$router.push('/research-farmer/request-testing-tag/request-testing-tag-without-field-certification')
                        } else {
                            this.formData = result.data
                            this.formData.org_type_id = result.data.org_type
                            this.formData.dealer_name_en = result.data.applicant_address.applicant_name
                            this.formData.dealer_name_bn = result.data.applicant_address.applicant_name_bn
                            this.formData.registered = result.data.req_test_tag_without_certificate.registered
                            this.formData.registration_number = result.data.req_test_tag_without_certificate.registration_number
                            this.formData.office_id = result.data.req_test_tag_without_certificate.office_id
                            this.formData.office_type_id = result.data.req_test_tag_without_certificate.office_type_id
                            this.formData.a_area_type_id = result.data.applicant_address.a_area_type_id
                            this.formData.a_division_id = result.data.applicant_address.a_division_id
                            this.formData.a_district_id = result.data.applicant_address.a_district_id
                            this.formData.a_upazilla_id = result.data.applicant_address.a_upazilla_id
                            this.formData.a_city_corporation_id = result.data.applicant_address.a_city_corporation_id
                            this.formData.a_union_id = result.data.applicant_address.a_union_id
                            this.formData.a_pouroshova_id = result.data.applicant_address.a_pouroshova_id
                            this.formData.a_ward_id = result.data.applicant_address.a_ward_id
                            this.formData.a_post_office_name = result.data.applicant_address.a_post_office_name
                            this.formData.a_village_name = result.data.applicant_address.a_village_name
                            this.formData.a_holding_number = result.data.applicant_address.a_holding_number
                            this.formData.a_holding_number_bn = result.data.applicant_address.a_holding_number_bn
                            this.formData.a_mobile_phone_no = result.data.applicant_address.a_mobile_phone_no
                            this.formData.a_email_address = result.data.applicant_address.a_email_address
                            // this.formData.producer_name_bn = result.data.req_test_tag_without_certificate.producer_name_bn
                            // this.formData.producer_name_en = result.data.req_test_tag_without_certificate.producer_name_en
                            // this.formData.production_year = result.data.before_showing_planting.production_year
                            // this.formData.date_of_packing = result.data.req_test_tag_without_certificate.date_of_packing
                            // this.formData.date_of_expiry = result.data.req_test_tag_without_certificate.date_of_expiry
                            // this.formData.nature_of_container_en = result.data.req_test_tag_without_certificate.nature_of_container_en
                            // this.formData.nature_of_container_bn = result.data.req_test_tag_without_certificate.nature_of_container_bn
                            // this.formData.date_of_sampling = result.data.req_test_tag_without_certificate.date_of_sampling
                            // this.formData.sample_unit_id = result.data.req_test_tag_without_certificate.sample_unit_id
                            // this.formData.sample_quantity = result.data.req_test_tag_without_certificate.sample_quantity
                            // this.formData.sample_no = result.data.req_test_tag_without_certificate.sample_no
                            // this.formData.locally_produced_imported = result.data.req_test_tag_without_certificate.locally_produced_imported
                            // this.formData.seed_infos = result.data.seed_infos
                            // this.formData.seed_infos.push(result.data.seed_infos)
                            this.getAreaTypeData(result.data.a_area_type_id)
                            this.isDisabledCheck()
                        }
                    }
                    this.$store.dispatch('mutateCommonProperties', { loading: false })
                    this.loading = false
                }
            },
            getWard () {
                if (this.formData.a_area_type_id === 1) {
                    const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList.filter(item => item.status === 0 && item.city_corporation_id === this.formData.a_city_corporation_id)
                    return objectData.map((obj, key) => {
                        if (this.$i18n.locale === 'bn') {
                            return { value: obj.value, text: obj.text_bn }
                        } else {
                            return { value: obj.value, text: obj.text_en }
                        }
                    })
                }
                if (this.formData.a_area_type_id === 2) {
                    const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList.filter(item => item.status === 0 && item.upazilla_id === this.formData.a_upazilla_id)
                    return objectData.map((obj, key) => {
                        if (this.$i18n.locale === 'bn') {
                            return { value: obj.value, text: obj.text_bn }
                        } else {
                            return { value: obj.value, text: obj.text_en }
                        }
                    })
                }
                if (this.formData.a_area_type_id === 3) {
                    const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList.filter(item => item.status === 0 && item.pouroshova_id === this.formData.a_pouroshova_id)
                    return objectData.map((obj, key) => {
                        if (this.$i18n.locale === 'bn') {
                            return { value: obj.value, text: obj.text_bn }
                        } else {
                            return { value: obj.value, text: obj.text_en }
                        }
                    })
                }
            },
            async generalSubmit () {
                if (this.isDisabled) {
                    this.formData.registration_number = null
                }
                this.loading = true
                this.isLoading = true
                let result = null
                if (!this.formData.id) {
                    result = await RestApi.postData(agriResearchServiceBaseUrl, testingTagRequestWithoutCetificationStore, this.formData)
                } else {
                    result = await RestApi.postData(agriResearchServiceBaseUrl, testingTagRequestWithoutCetificationUpdate, this.formData)
                }
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    this.loading = false
                    this.isLoading = false
                    this.$router.push('/research-farmer/request-testing-tag/request-testing-tag-without-field-certification')
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#D6E09B'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
                this.loading = false
                this.isLoading = false
            },
            getOfficeName (id) {
                const objectData = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(item => item.district_id === parseInt(id)).filter(item => item.org_id === 14).filter(item => item.office_type_id === 113)
                this.officeList = objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            }
        }
    }
</script>
<style scoped>
    .form-control {
        padding: 0.375rem 0.75rem
    }
</style>
