export const designationWiseUser = '/master-project-setup/officer-list'
// Step Info Part
export const getStep = '/master-seed-certification/tab-position/list'

// General Info Part
export const getGeneralInfo = '/master-seed-certification/general-information/list'
export const storeGeneralInfo = '/master-seed-certification/general-information/store'

// General Info Part
export const getFileInfo = '/master-seed-certification/document-attachment/list'
export const storeFileInfo = '/master-seed-certification/document-attachment/store'

// Field And Seed Info Part
export const getFieldSeedInfo = '/master-seed-certification/field-seed-info/list'
export const storeFieldSeedInfo = '/master-seed-certification/field-seed-info/store-field-info'
export const storeSourceInfo = '/master-seed-certification/field-seed-info/store-seed-source'
export const storeSCAPlotDetails = '/master-seed-certification/field-seed-info/store-sca-plot'
export const getFieldSeedInfoById = '/master-seed-certification/field-seed-info/field-info-by-id'
// export const getApplicationInfo = '/master-seed-certification/field-seed-info/application-info'

// lot offer
export const applicationList = '/master-seed-certification/lot-offer/list'
export const storeLotOffer = '/master-seed-certification/lot-offer/store'
export const getAppData = '/master-seed-certification/lot-offer/show'

// Payment Info Part
export const getPaymentInfo = '/master-seed-certification/payment/list'
export const storePaymentInfo = '/master-seed-certification/payment/store'

// After Sowing Planting Part
export const getAfterSowingInfo = '/master-seed-certification/after-sowing-planting/list'
export const storeAfterSowingInfo = '/master-seed-certification/after-sowing-planting/store'

// After Flowers Come Part
export const getFlowersComeInfo = '/master-seed-certification/flowers-come/list'
export const storeFlowersComeInfo = '/master-seed-certification/flowers-come/store'

// After Harvesting Come Part
export const getAfterHarvestingInfo = '/master-seed-certification/after-harvesting/list'
export const storeAfterHarvestingInfo = '/master-seed-certification/after-harvesting/store'
export const storeLotOfferInfo = '/master-seed-certification/after-harvesting/store-lot-offer'
export const lotOfferInfoData = '/master-seed-certification/after-harvesting/lot-offer-info'
// Application List
export const getApplicationList = '/master-seed-certification/citizen-profile/application-list'
export const getSingleApplication = '/master-seed-certification/citizen-profile/general-info'

// Request for testing and Tag Issue
export const getTagAndIssue = '/testing-tag-payment/list'
export const getSingleTagAndIssue = '/testing-tag-payment/tabone'
export const tagAndIssuePayment = '/testing-tag-payment/tabtwostore'
export const testingTagRequestWithoutCetificationStore = '/testing-tag-request-without-certification/store'
export const testingTagRequestWithoutCetificationUpdate = '/testing-tag-request-without-certification/update'
export const testingTagRequestWithoutCetification = '/testing-tag-request-without-certification/index'
export const testingTagRequestWithoutCetificationShow = '/testing-tag-request-without-certification/show'
export const testingTagRequestWithoutCetificationSubmit = '/testing-tag-request-without-certification/submit'
export const testTagResultApi = '/test_result_and_tag_requisition/index'
export const getPaymentReceipt = '/testing-tag-request-without-certification/paymentReceipt'
export const getSeedPaymentReceipt = '/testing-tag-request-without-certification/seedPaymentReceipt'
export const gettagPaymentReceipt = '/testing-tag-request-without-certification/tagPaymentReceipt'
export const tagPrintModalApi = '/testing-service-management/print'
export const detailsVieweModalApi = '/testing-service-management/print-details'
export const getLotNos = '/testing-service-management/lot-no'

// TestingTagRequestWithCetification Info Part
export const testingTagRequestWithCetification = '/testing-tag-request-with-certification/list'
export const storeTestingPaymentInfo = '/testing-tag-request-with-certification/store'
export const paymentStore = '/testing-tag-request-without-certification/payment'

// TestResultAndTagRequisition
export const storeTagPaymentInfo = '/test_result_and_tag_requisition/store'
export const SeedPackage = '/test_result_and_tag_requisition/package'

// get application data
export const getApplicationData = '/master-seed-certification/general-information/show'
export const getApplicationRemarks = '/master-seed-certification/general-information/reject'

/** Publication applicant panel api
=========================================== */
// publication proposal api
export const circularPublicationListApi = '/research-panel/publication-proposal-submission/list'
export const proposalSubmissionListApi = '/research-panel/publication-proposal-submission/submission-list'
export const publicationProposalStoreApi = '/research-panel/publication-proposal-submission/store'
export const publicationProposalUpdateApi = '/research-panel/publication-proposal-submission/update'

// Subscription Form api
export const subscriptionFormListApi = '/research-panel/publication-subscription-form/list'
export const subscriptionFormListApiPaymentStatus = '/research-panel/subscription-list/payment-status/'
export const subscriptionFormListApiPaymentStatusApi = '/research-panel/publication-subscription-form/payment-status/'
export const subscriptionFormStoreApi = '/research-panel/publication-subscription-form/store'
export const subscriptionListApi = '/research-panel/subscription-list/list'

// profile data
export const profileListData = 'research-panel/profile'
export const profileListDataStore = 'research-panel/profile-store'

/** Research Management apis
 * ================================================= */
// Proposal Invitation
// proposal application
export const getApprovedApplicationList = '/research-panel/research-repost-submission/list'
export const approvedApplicationReportStore = '/research-panel/research-repost-submission/store'
export const getProposalData = 'research-panel/research-repost-submission/show'
export const proposalPublishList = '/national-research/proposal-invitation/publish-list'

// Research Proposal Submission
export const proposalResearchSubmissionListApi = '/national-research-panel/proposal-submission/list'
export const researchProposalDetailsApi = '/national-research-panel/proposal-submission/details'
export const divisionalProposalSubStoreUpdate = '/national-research-panel/proposal-submission/divisional-store-update'
export const brriProposalSubStoreUpdate = '/national-research-panel/proposal-submission/brri-store-update'
export const divisionalProposalSubStore = '/national-research-panel/proposal-submission/divisional-store'
export const divisionalProposalSubUpdate = '/national-research-panel/proposal-submission/divisional-store'
export const proposalGeneralStoreApi = '/national-research-panel/proposal-submission/store'
export const proposalInvestigationStoreApi = '/national-research-panel/proposal-submission/investigator-store'
export const proposalDescriptionStoreApi = '/national-research-panel/proposal-submission/description-store'
export const proposalLocationStoreApi = '/national-research-panel/proposal-submission/location-store'
export const proposalActivityStoreApi = '/national-research-panel/proposal-submission/activity-store'
export const proposalMethodologyStoreApi = '/national-research-panel/proposal-submission/methodology-store'
export const proposalBudgetInfoStoreApi = '/national-research-panel/proposal-submission/budget-store'
export const getCoordinatorInfoApi = '/national-research-panel/proposal-submission/get-coordinator-info'
export const usersByDesignationApi = '/user/list-by-designation'
export const projectInfo = '/national-research-panel/proposal-submission/get-project-info'
export const projectList = '/master-project-setup/project-list'
export const projectSubInfo = '/national-research-panel/proposal-submission/get-sub-project-info'

// Activity Plan
const activityPlan = 'research-panel/activity-plan'
export const proposalActivityListApi = activityPlan + '/proposal-activity-list'
export const proposalActivitiesApi = activityPlan + '/activity-list'
// export const updateActivityDatesApi = activityPlan + '/update-activity-dates'
export const activityDetailsApi = activityPlan + '/activity-details'
export const storeActivityDocApi = activityPlan + '/store-activity-doc'
// export const deleteActivityDocApi = activityPlan + '/destroy-activity-doc'

// Fund request
export const fundRequestListApi = 'research-panel/fund-request/list'
export const fundAllocatedAmountPendingCheckApi = 'research-panel/fund-request/get-allocated-amount-pending'
export const fundAllocatedAmountApi = 'research-panel/fund-request/get-allocated-amount'
export const fundRequestStoreApi = 'research-panel/fund-request/store'

export const projectListApi = 'research-panel/project-title-list'
export const inspectionOneShow = '/master-seed-certification/inspection-report/show'
export const inspectionSecondShow = '/master-seed-certification/inspection-report/second-show'
export const inspectionThirdShow = '/master-seed-certification/inspection-report/third-show'

// User List
export const getUserList = '/user/user-list'

// Monitoring Schedule
export const monitoringScheduleProposalListApi = 'research-panel/monitoring-schedule/proposal/list'
export const monitoringScheduleProposalShowApi = 'research-panel/monitoring-schedule/proposal/show'

// monitoring report
export const monitoringReportListApi = 'national-research/monitoring-report/list'
export const monitoringReportShowApi = 'national-research/monitoring-report/show'
